<template>

  <!-- Opp News Draft Form -->
  <j-card
    title="Create News Item"
    class="opp-news-draft-form"
  >

    <!-- Form -->
    <j-card-text>
      <j-form model="opps.newsDraft">
        <v-col cols="9">
          <v-row>

            <!-- Section -->
            <j-control
              name="section_slug"
              sm="12"
            />

            <!-- Title -->
            <j-control
              name="title"
              sm="12"
            />
          </v-row>
        </v-col>

        <!-- Badge -->
        <j-control
          name="badge_src"
          :metadata="newsDraft.cdnMetadata"
          aspect-ratio="1"
          cols="3"
        />

        <!-- Body -->
        <j-control
          name="body"
          rows="8"
        />

        <!-- Link / URL -->
        <j-control
          name="url"
          sm="6"
        />

        <!-- Contact -->
        <j-control
          name="contact"
          sm="6"
        />

      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Options -->
    <template #options>
      <slot name="options" />
    </template>

  </j-card>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('opps')

export default {
  name: 'OppNewsDraftForm',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'newsDraft'
    ])
  }
}
</script>
