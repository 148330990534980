<template>

  <!-- Opp News Draft Panel -->
  <div id="opps-news-draft-panel">

    <!-- Opp Form -->
    <OppNewsDraftForm class="mb-4">
      
      <!-- Actions -->
      <template #actions>

        <!-- Create -->
        <j-button
          label="Create"
          :processing="newsDraft.saving"
          :disabled="newsDraft.pristine"
          @action="handleSave"
        />
      </template>

      <!-- Options -->
      <template #options>

        <!-- Cancel -->
        <j-button
          label="Cancel"
          type="option"
          @action="handleCancel"
        />

      </template>
    </OppNewsDraftForm>
  </div>
</template>

<script>

// Components
import OppNewsDraftForm from '@/components/Opps/OppNewsDraftForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('opps')

export default {
  name: 'OppNewsDraftPanel',
  components: {
    OppNewsDraftForm
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.NEW_DRAFT()
    })
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp',
      'newsDraft'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'saveNewsDraft'
    ]),
    // Mutations
    ...mapMutations([
      'NEW_DRAFT'
    ]),
    // Handlers
    handleCancel () {
      this.$router.push({ name: 'opps-details' })
    },
    handleSave () {
      this.saveNewsDraft().then(() => {
        this.$router.push({ name: 'opps-details' })
      })
    }
  }
}
</script>
